<template>
  <div>
    <v-row class="mb-2" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(studentData.salaryAmountStudent ? studentData.salaryAmountStudent : 0) +
                  studentData.currencySymbol
                }}
              </h2>
              <span>الواردات الكلية</span>
            </div>

            <v-icon size="30" color="primary" class="rounded-0">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(
                    (studentData.salaryAmountStudent ? studentData.salaryAmountStudent : 0) -
                      (studentData.discountAmountStudent ? studentData.discountAmountStudent : 0),
                  ) + studentData.currencySymbol
                }}
              </h2>
              <span>الواردات بعد التخفيض</span>
            </div>

            <v-icon size="30" color="secondary" class="rounded-0"> fa-coins </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(studentData.discountAmountStudent ? studentData.discountAmountStudent : 0) +
                  studentData.currencySymbol
                }}
              </h2>
              <span>التخفيض</span>
            </div>

            <v-icon size="30" color="warning" class="rounded-0"> fa-cash-register </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(studentData.paymentAmountStudent ? studentData.paymentAmountStudent : 0) +
                  studentData.currencySymbol
                }}
              </h2>
              <span>المبالغ المستحصلة</span>
            </div>

            <v-icon size="40" color="error" class="rounded-0">
              {{ icons.mdiCash }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mb-2" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="4">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(
                    (studentData.salaryAmountStudent ? studentData.salaryAmountStudent : 0) -
                      ((studentData.paymentAmountStudent ? studentData.paymentAmountStudent : 0) +
                        (studentData.discountAmountStudent ? studentData.discountAmountStudent : 0)),
                  ) + studentData.currencySymbol
                }}
              </h2>
              <span>المبالغ المتبقية</span>
            </div>

            <v-icon size="40" color="warning" class="rounded-0">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">فواتير الطلاب</h1>
      <v-row>
        <v-col cols="12">
          <v-btn
            tile
            color="secondary"
            class="ml-2 mb-4 mb-md-0 mb-sm-0"
            :block="isScreenXs"
            @click="goToStudentHasNoBillsPage"
          >
            الطلاب الذين ليس لديهم فاتورة<v-icon right> fa-arrow-left </v-icon>
          </v-btn>
          <v-btn
            tile
            color="#90A4AE"
            class="ml-2 mb-4 mb-md-0 mb-sm-0 white--text"
            :block="isScreenXs"
            @click="goToStudentTransferredPage"
          >
            الطلاب المنقولين<v-icon right> fa-arrow-left </v-icon>
          </v-btn>
          <v-btn
            tile
            color="warning"
            class="ml-2 mb-4 mb-md-0 mb-sm-0"
            :block="isScreenXs"
            @click="goToStudenBillsDiscountsPage"
          >
            الفواتير المخصومة<v-icon right> fa-arrow-left </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn
              tile
              color="primary"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              :block="isScreenXs"
              @click="addDialog.open = true"
            >
              اضافة فاتورة <v-icon right> fa-plus </v-icon>
            </v-btn>
            <!-- <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="goToInvoicePage"> فتح الفاتورة
                <v-icon right> fa-plus </v-icon>
              </v-btn> -->
            <v-btn tile color="success" :block="isScreenXs" @click="dialogExcel.open = true">
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2" cols="12">
          <v-select
            v-model="tableModifier.billType"
            :items="billsTypeSelectItems"
            dense
            label="اختيار نوع الفاتورة"
            outlined
            item-text="text"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col md="2" cols="12">
          <v-select
            v-model="tableModifier.isDeleted"
            :items="isDeletedBillSelectItems"
            dense
            label="اختيار نوع الفاتورة"
            outlined
            item-text="text"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.start_date"
                dense
                label="من"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.end_date"
                dense
                label="الى"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white">fa-search</v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.student_name="{ item }">
              <a @click="goToBillsPage(item.student_id, item.student_name)">{{ item.student_name }}</a>
            </template>
            <template v-slot:item.account_division_current="{ item }">
              {{
                (item.account_division_current ? item.account_division_current.class_name : '') +
                ' - ' +
                (item.account_division_current ? item.account_division_current.leader : '')
              }}
            </template>
            <template v-slot:item.forthisYearSalaryAmount="{ item }">
              {{ numberWithComma(item.forthisYearSalaryAmount) }}
            </template>
            <template v-slot:item.forthisYearPaymentAmount="{ item }">
              {{ numberWithComma(item.forthisYearPaymentAmount) }}
            </template>
            <template v-slot:item.forthisYearDiscountAmount="{ item }">
              {{ numberWithComma(item.forthisYearDiscountAmount) }}
            </template>
            <template v-slot:item.forthisYearRemaining="{ item }">
              {{ numberWithComma(item.forthisYearRemaining) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="!tableModifier.isDeleted">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FDD835" v-bind="attrs" size="20" class="ml-2" v-on="on" @click="printPage(item)">
                    fa-print
                  </v-icon>
                </template>
                <span>طباعة</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#9FA8DA"
                    v-bind="attrs"
                    size="20"
                    class="ml-2"
                    v-on="on"
                    @click="transferredStudent(item)"
                  >
                    fa-exchange-alt
                  </v-icon>
                </template>
                <span>نقل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="secondary"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToBillsPage(item.student_id, item.student_name)"
                  >
                    fa-table
                  </v-icon>
                </template>
                <span>عرض الفواتير</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- download excel-->
    <v-dialog v-model="dialogExcel.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تحميل اكسل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogExcel.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="class_school_id"
                    :items="class_select"
                    :loading="classLoading"
                    :item-text="item => item.class_name + ' - ' + item.leader"
                    item-value="_id"
                    :rules="rules.required"
                    clearable
                    label="الصف والشعبة"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogExcel.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="xlsxData.downloadLoading"
            :disabled="!dialogExcel.isFormValidAdd"
            @click="getAllDataAxios"
          >
            تحميل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- transferredStudent -->
    <v-dialog v-model="dialogTransferred.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">نقل الطالب</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogTransferred.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="dialogTransferred.data.transferred_note"
                    label="الملاحظة"
                    placeholder="ملاحظة نقل الطالب"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogTransferred.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogTransferred.loading"
            :disabled="!dialogTransferred.isFormValid"
            @click="transferredStudentSubmit"
          >
            نقل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="addData.student_id"
                    :rules="rules.student_id"
                    :loading="studentLoading"
                    :items="studentsData"
                    item-text="account_name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="اسم الطالب"
                    @click:clear="addData.student_id = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="addData.service_type"
                    :rules="rules.service_type"
                    :loading="serviceLoading"
                    :items="servicesData"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="نوع الخدمة"
                    @click:clear="addData.service_type = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.salaryAmount"
                    label="المبلغ"
                    :rules="rules.salaryAmount"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    @change="salaryPlusDiscountAmountChange"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.discountAmount"
                    label="الخصم"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.salaryPlusDiscountAmount"
                    label="المبلغ الكلي بعد الخصم"
                    outlined
                    disabled
                    filled
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.paymentAmount"
                    label="المبلغ المدفوع"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="addData.remainingAmount"
                    label="المبلغ المتبقي"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    v-model="menuNextPayment"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.next_payment"
                        :disabled="nextPaymentDisable"
                        dense
                        label="تاريخ الدفعة القادمة"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.next_payment" @input="menuNextPayment = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.date"
                        dense
                        :rules="rules.date"
                        label="تاريخ انشاء الفاتورة"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.date" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- <v-col cols="12">
                  <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.date" dense label="تاريخ انشاء الفاتورة" :rules="rules.date"
                        outlined clearable readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.date" @input="menuDate = false">
                    </v-date-picker>
                  </v-menu>
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.invoice_number"
                    label="رقم الوصل"
                    outlined
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="1" row-height="10" v-model="addData.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialog.loading"
            :disabled="!addDialog.isFormValidAdd"
            @click="addStudentBillsData"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!--- Dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import { getCurrentDateInString } from '@/constant/date'
import numberWithComma from '@/constant/number'
import router from '@/router'
import { mdiCash, mdiCashMultiple } from '@mdi/js'

export default {
  data () {
    return {
      // billsTypeSelected: null

      dialogTransferred: {
        data: {
          is_transferred: true,
          student_id: null,
          transferred_note: null,
        },
        loading: false,
        open: false,
        isFormValid: false,
      },

      classLoading: false,

      class_select: [],

      class_school_id: null,

      dialogExcel: {
        open: false,
        isFormValidAdd: false,
        loading: false,
      },

      account_type: null,

      menuStartDate: false,

      menuEndDate: false,

      icons: {
        mdiCashMultiple,
        mdiCash,
        mdiCashMultiple,
      },

      tableModifier: {
        billType: null,
        isDeleted: false,
        start_date: null,
        end_date: null,
      },

      rules: {
        student_id: [value => !!value || 'اسم الطالب مطلوب'],
        service_type: [value => !!value || 'الخدمة مطلوبة'],
        salaryAmount: [value => !!value || 'المبلغ مطلوب'],
        type: [value => !!value || 'نوع الفاتورة مطلوب'],
        date: [value => !!value || 'تاريخ انشاء الفاتورة مطلوب'],
        required: [value => !!value || 'الحقل مطلوب'],

        // next_payment: [value => !!value || 'تاريخ الدفعة القادمة مطلوب'],
      },

      menuNextPayment: false,

      menuDate: false,

      addData: {
        student_id: null,
        salaryAmount: null,
        paymentAmount: null,
        discountAmount: null,
        remainingAmount: null,
        next_payment: null,
        desc: null,
        invoice_number: null,
        service_type: null,
        salaryPlusDiscountAmount: null,
        date: getCurrentDateInString(),
      },

      deleteItemLoading: false,

      content_url: null,

      editRules: {
        Rules: [
          value => !!value || 'الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],
        ensureRules: [
          value => !!value || 'تاكيد الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],
      },

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'قواتير الطلاب',
        autoWidth: true,
        bookType: 'xlsx',
      },

      allData: [],

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      billsTypeSelectItems: [
        { text: 'الكل', value: null },
        { text: 'المدفوعة', value: true },
        { text: 'غير المدفوعة', value: false },
      ],

      isDeletedBillSelectItems: [
        { text: 'غير محذوفة', value: false },
        { text: 'محذوفة', value: true },
      ],

      servicesData: [],

      serviceLoading: false,

      studentsData: [],

      studentLoading: false,

      nextPaymentDisable: false,

      studentData: {
        currency: 'IQD',
        currencySymbol: ' IQD',
        salaryAmountStudent: 0,
        discountAmountStudent: 0,
        paymentAmountStudent: 0,
      },

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          {
            text: 'اسم الطالب',
            sortable: false,
            value: 'student_name',
          },
          { text: 'الصف والشعبة', value: 'account_division_current' },
          { text: 'الهاتف', value: 'student_mobile' },
          { text: 'المبلغ الكلي', sortable: false, value: 'forthisYearSalaryAmount' },
          { text: 'المبلغ المدفوع', sortable: false, value: 'forthisYearPaymentAmount' },
          { text: 'الخصم', sortable: false, value: 'forthisYearDiscountAmount' },
          { text: 'المتبقي', sortable: false, value: 'forthisYearRemaining' },
          // { text: 'مجموع المبلغ الكلي لكل السنين', sortable: false, value: 'salaryAllAmount' },
          // { text: 'مجموع المبلغ المدفوع لكل السنين', sortable: false, value: 'paymentAllAmount' },
          // { text: 'مجموع الخصم لكل السنين', sortable: false, value: 'discountAllAmount' },
          // { text: 'المتبقي لكل السنين', sortable: false, value: 'remainingAll' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    'addData.salaryAmount': {
      handler () {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.paymentAmount': {
      handler () {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.discountAmount': {
      handler () {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.remainingAmount': {
      handler () {
        if (this.addData.remainingAmount <= 0) {
          this.nextPaymentDisable = true
          this.addData.next_payment = null
        } else {
          this.nextPaymentDisable = false
        }
      },
      // deep: true,
    },

    'tableModifier.billType': {
      handler () {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.isDeleted': {
      handler () {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.start_date': {
      handler () {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler () {
        this.getDataAxios()
      },
      // deep: true,
    },

    tableOptions: {
      handler () {
        this.getDataAxios()
      },
      deep: true,
    },

    '$route.query.search': {
      handler (search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },

    '$vuetify.breakpoint': {
      handler () {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },
  },

  created () {
    this.getEmployee()
    this.getServices()
    this.getClassSchool()
    this.account_type = JSON.parse(localStorage.getItem('results')).account_type
  },

  methods: {
    async getDataAxios () {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getStudentBills({
        is_transferred: false,
        isDeleted: this.tableModifier.isDeleted,
        isPaid: this.tableModifier.billType,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        study_year,
        page,
        limit: itemsPerPage,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
        this.studentData = response.data.results.studentData
      }
    },

    async getClassSchool () {
      this.classLoading = true
      this.class_select = []

      const response = await Api.getClassSchool()
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.classLoading = false
        this.class_select = response.data.results

        this.class_select.unshift({
          class_name: 'جميع الصفوف',
          leader: '',
          _id: 'all',
        })
        // this.leader_select = response.data.results
      }
    },

    salaryPlusDiscountAmountChange () {
      this.addData.salaryPlusDiscountAmount =
        (this.addData.salaryAmount ? this.addData.salaryAmount : 0) -
        (this.addData.discountAmount ? this.addData.discountAmount : 0)
      this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0
    },

    paymentChange () {
      this.addData.remainingAmount =
        (this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0) -
        (this.addData.paymentAmount ? this.addData.paymentAmount : 0)
      this.addData.remainingAmount ? this.addData.remainingAmount : 0
    },

    goToInvoicePage () {
      this.$router.push('invoice/4987')
    },

    showImage (image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },

    search () {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    async addStudentBillsData () {
      let payments = []
      const study_year = JSON.parse(localStorage.getItem('study_year'))
      if (this.addData.salaryAmount) {
        payments.push({
          amount: this.addData.salaryAmount,
          type: 'salary',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }

      if (this.addData.paymentAmount) {
        payments.push({
          amount: this.addData.paymentAmount,
          type: 'payment',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }

      if (this.addData.discountAmount) {
        payments.push({
          amount: this.addData.discountAmount,
          type: 'discount',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }
      this.addDialog.loading = true

      const response = await Api.addStudentBills({
        student_id: this.addData.student_id,
        service_type: this.addData.service_type,
        desc: this.addData.desc,
        invoice_number: this.addData.invoice_number,
        date: this.addData.date,
        payments,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getDataAxios()
      }
    },

    deleteItem (item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteItemLoading = true

      const response = await Api.removeStudentBills(this.deletedItem.student_id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async getEmployee () {
      this.studentLoading = true

      const response = await Api.getAllStudents()

      if (response.status === 401) {
        this.studentLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.studentLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.studentLoading = false
        this.studentsData = response.data.results
      }
    },

    async getServices () {
      this.serviceLoading = true

      const response = await Api.getService('واردات طلاب')

      if (response.status === 401) {
        this.serviceLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.serviceLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.serviceLoading = false
        this.servicesData = response.data.results
      }
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    Edit (item) {
      this.editedItem = { ...item }
      this.EditDialog = true
    },

    async submitEdit () {
      if (this.editedItem.account_show !== this.editedItem.ensure) {
        this.showDialogfunction('تاكيد الرمز خاطئ', '#FF5252')
      } else {
        this.EditLoading = true

        const response = await Api.edit(
          this.editedItem._id,
          sha512(this.editedItem.account__show),
          this.editedItem.account__show,
        )

        if (response.status === 401) {
          this.$store.dispatch('submitLogout')
        } else if (response.status === 500) {
          this.EditDialog = false
          this.EditLoading = false
          this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
        } else {
          this.EditLoading = false
          this.EditDialog = false
          this.getDataAxios()
          this.showDialogfunction(response.data.results, 'primary')
        }
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async getAllDataAxios () {
      this.xlsxData.downloadLoading = true

      let { search } = this.table
      if (!search) {
        search = ''
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getAllStudentBills({
        isDeleted: this.tableModifier.isDeleted,
        isPaid: this.tableModifier.billType,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        study_year,
        class_school_id: this.class_school_id,
        search,
        is_transferred: false,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.xlsxData.downloadLoading = false
        this.dialogExcel.open = false
        this.allData = response.data.results
        this.handleDownload()
      }
    },

    goToAddPage () {},

    goToBillsPage (student_id, student_name) {
      router.push(`studentBills/showBills/student_id/${student_id}/student_name/${student_name}`)
    },

    handleDownload () {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['اسم الطالب', 'المبلغ الكلي', 'المبلغ المدفوع', 'الخصم', 'المتبقي']
        const filterVal = [
          'student_name',
          'forthisYearSalaryAmount',
          'forthisYearPaymentAmount',
          'forthisYearDiscountAmount',
          'forthisYearRemaining',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    printPage (item) {
      // let routeData = this.$router.resolve({ name: 'studentBillInvoice', params: { student_id: item.student_id, student_name: item.student_name, student_mobile: item.student_name, student_class: (item.account_division_current.class_name + ' - ' + item.account_division_current.leader) } });

      let routeData = this.$router.resolve({ name: 'studentBillInvoice' })
      window.open(routeData.href, '_blank')

      localStorage.setItem('studentBillInvoice', JSON.stringify(item))

      // router.push('studentBillInvoice')
    },

    goToStudentHasNoBillsPage () {
      router.push('studentHasNoBills')
    },

    goToStudentTransferredPage () {
      router.push('studentBills/studentBillsTransferred')
    },

    goToStudenBillsDiscountsPage () {
      router.push('studentBillsDiscounts')
    },

    transferredStudent (item) {
      this.dialogTransferred.open = true
      this.dialogTransferred.data.student_id = item.student_id
    },

    async transferredStudentSubmit () {
      this.dialogTransferred.loading = true

      const response = await Api.transferredStudentDB({
        is_transferred: this.dialogTransferred.data.is_transferred,
        transferred_note: this.dialogTransferred.data.transferred_note,
        student_id: this.dialogTransferred.data.student_id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogTransferred.loading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.dialogTransferred.loading = false
        this.dialogTransferred.open = false
        this.showDialogfunction(response.data.message, 'primary')
        this.getDataAxios()
        this.dialogTransferred.data.transferred_note = null
      }
    },

    numberWithComma,
  },
}
</script>

<style scoped>
.image_table {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}

table.v-table tbody td {
  color: black;
}
</style>
